<template>
    <div>
      <div class="swiper-container pos-rel">
        <div class="swiper-wrapper">
          <div class="swiper-slide pos-rel">
            <!-- <img src="../assets/images/product_detail_banner.png" alt=""> -->
            <!-- <div class="text-box flex flex-center flex-column color-w">
              <p class="title flex">无感跌倒监测报警器</p>
              <p class="sub-title font30">匠心产品，副标题或者说明文案</p>
            </div> -->
          </div>
        </div>
      </div>
      <div>
        <div class="nav-bread flex flex-align-center font16 color-767E98">
            <a href="/"><span class="color-767E98">首页</span></a>     <span class="margin-0-10">/</span>
            <a href="/productList"><span class="color-767E98">产品</span> </a>    <span class="margin-0-10">/</span>
            <span class="color-434A63">{{ query.name }}</span>
        </div>
      </div>
      <div class="product-img">
        <div v-for="(item, index) in productDetail.imgs" :key="index">
          <img
          
          :src="item" alt="">
        </div>
      </div>
      <div class="detail-containear color-434A63 font16">
           <div class="content" v-html="productDetail.content">
               
           </div>
      </div>
    </div>
</template>
<script>
import http from '@/request';
import Swiper from 'swiper'
export default {
  name: 'App',
  data () {
    return {
        navList: [
            { name: '首页' }, { name: '品牌' }, { name: '产品' }, { name: '商城' },{ name: '联系我们' }
        ],
        productDetail: {},
        query: {}
    }
  },
  mounted () {
    this.query = this.$route.query
    this.getProductInfo();
    
  },
  methods: {
    getProductInfo () {
      http.get('/productDetail', {
        id:this.query.id
      }).then((res) => {
        this.productDetail = res;
        
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 630px) {
  .top-banner {
    padding: 20px !important;
    font-size: 30px !important;
  }
  .nav-bread {
    padding: 20px 10px !important;
    background-color: #FFFFFF;
  }
  .detail-containear {
    margin: 0 10px !important;
  }
}
@media screen and (max-width: 766px) {
  .product-img {
    img {
      width: 100% !important;
    }
  }
}
.nav-bread {
    padding: 40px 80px;
    background-color: #FFFFFF;
}
.product-img {
  img {
    width: 60vw;
  }
  text-align: center;
    background-size: 100% 100%;
    position: relative;
    p {
        font-size: 48px;
        position: absolute;
        right: 108px;
        top: 64px;
    }
}
.detail-containear {
    padding: 60px 0;
    background-color: #F2F3F5;
    .content {
        margin: 0 auto;
        max-width: 800px;
        line-height: 30px;
    }
}
.swiper-container {
  width: 100%;
  height: 50vw;
  font-size: 0;
  overflow: hidden;
  .swiper-wrapper {
    width: 100%;
  }
  .swiper-slide {
    background: url('../assets/images/product_detail_banner.png') center center no-repeat;
    width: 100% !important;
    background-size: 100% 100%!important;
    height: 100%!important;
    .title {
        font-size: 50px;
    }
    .text-box {
      top: 120px;
      right: 120px;
      z-index: 2;
      font-size: 68px;
      position: absolute;
    }
    .sub-title {
      opacity: 0.8;
    }
    .btn {
      width: 160px;
      height: 42px;
      font-size: 14px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      margin-top: 30px;
      border: 1px solid #FFFFFF;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>