<template>
    <div>
      <div class="swiper-container pos-rel">
        <div class="swiper-wrapper">
          <div class="swiper-slide pos-rel">
            <div class="text-box color-w">
              <p class="title">小可智能一键呼救报警器</p>
              <p class="sub-title font30">4种求救方式 | 双重人工保障</p>
            </div>
          </div>
        </div>
        <!-- <div class="switch-box flex">
          <p 
          @click="handlerSwitch(index)"
          :class="[swiperIndex == index ? 'active':'']"
          class="switch-point" v-for="(item, index) in bannerList" :key="index"></p>
        </div> -->
      </div>
      <div class="product-containear">
        <div class="w90vw">
          <div class="flex flex-center font-50 title color-434A63" id="box1">一键呼救报警器</div>
          <div  class="margin-t-80 flex">
            <div class="product-box">
              <div @click="goDetail(item)"
              class="flex flex-center flex-column product-item pointer" v-for="(item, index) in productList" :key="index">
                <img class="product-img" :src="item.mallImg" alt="">
                <p class="f-600 font20 margin-t-16">{{ item.name }}</p>
                <p class="font12 product-price">RMB {{ item.price }}</p>
                <div 
                @click.stop="goPage('/shoppingMall')"
                class="button-pay flex flex-center font12 color-w pointer">购买</div>
                <p  class="btn-enter font12">进一步了解 <img style="width:10px;height:10px" src="../assets/images/arrow_r.png" alt=""></p>
              </div>
            </div>
          </div>
          <div 
          class="flex flex-center font14 color-434A63">
              <el-pagination
              @current-change="handlerChange"
              @prev-click="handlerChange"
              @next-click="handlerChange"
              :page-size="8"
              layout="prev, pager, next"
              :total="goodsTotal">
            </el-pagination>
          </div>
          <div class="flex flex-center font-50 title color-434A63" id="box2">24小时人工服务</div>
          <div  class="margin-t-60 flex">
            <div class="flex flex-wrap product-box">
              <div  @click="goDetail(item)"
              class="flex flex-center flex-column product-item pointer" v-for="(item, index) in serviceList" :key="index">
                <img class="product-img" :src="item.mallImg" alt="">
                <p class="f-600 font20 margin-t-16">{{ item.name }}</p>
                <p class="font12 product-price">RMB {{ item.price }}</p>
                <div 
                @click.stop="goPage('/shoppingMall')"
                class="button-pay flex flex-center font12 color-w pointer">购买</div>
                <p @click="goDetail(item)" class="btn-enter font12">进一步了解 <img style="width:10px;height:10px" src="../assets/images/arrow_r.png" alt=""></p>
              </div>
            </div>
          </div>
          <div 
          class="flex flex-center font14 color-434A63">
              <el-pagination
              @current-change="handlerChange1"
              @prev-click="handlerChange1"
              @next-click="handlerChange1"
              :page-size="8"
              layout="prev, pager, next"
              :total="serviceTotal">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import http from '@/request';
import Swiper from 'swiper'
export default {
  name: 'App',
  data () {
    return {
        navList: [
            { name: '首页' }, { name: '品牌' }, { name: '产品' }, { name: '商城' },{ name: '联系我们' }
        ],
        bannerList: [],
        productList: [],
        serviceList: [],
        mySwiper: null,
        swiperIndex: 0,
        goodsPage: 1,
        servicePage: 1,
        goodsTotal: 1,
        serviceTotal: 1
    }
  },
  mounted () {
    this.getProductList();
    this.getServicePage();
  },
  methods: {
    handlerChange (e) {
      this.goodsPage = e;
     
      this.getProductList()
      document.body.scrollTop = document.getElementById('box1').offsetTop
       // firefox
        document.documentElement.scrollTop = document.getElementById('box1').offsetTop
    },
    handlerChange1 (e) {
      this.servicePage = e;
      this.getServicePage();
      document.body.scrollTop = document.getElementById('box2').offsetTop
       // firefox
        document.documentElement.scrollTop = document.getElementById('box2').offsetTop
    },
    goPage (path) {
        this.$router.push({ path: path })
    },
    goDetail (item) {
      this.$router.push({ name: 'productDetail', query: {
        id: item.id,
        name: item.name
      } })
    },
    handlerSwitch (index) {
      this.mySwiper.slideTo(index, 1000, false)
    }, 
    getProductList () {
      http.get('/productList', {
        type: 'CALL',
        current: this.goodsPage,
        size: 8
      }).then((res) => {
        this.productList = res.data;
        this.goodsTotal = res.totalData;
        
       // firefox
      })
    },
    getServicePage () {
      http.get('/productList', {
        type: 'SERVICE',
        current: this.servicePage,
        size: 8
      }).then((res) => {
        this.serviceList = res.data;
        this.serviceTota = res.totalData
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 500px) {
  .product-containear {
    .title {
      font-size: 40px !important;
    }
  }
}
.swiper-container {
  width: 100%;
  height: 50vw;
  font-size: 0;
  overflow: hidden;
  .swiper-wrapper {
    width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    background: url('../assets/images/product_list_banner.png') center center no-repeat;
    width: 100% !important;
    background-size: 100% 100%!important;
    height: 100%!important;
    .text-box {
      top: 0;
      left: 0;
      z-index: 2;
      font-size: 68px;
      position: absolute;
      padding: 120px 0 0 120px;
    }
    .sub-title {
      font-size: 40px;
      opacity: 0.8;
    }
    .btn {
      width: 160px;
      height: 42px;
      font-size: 14px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      margin-top: 30px;
      border: 1px solid #FFFFFF;
    }
  }
}
.product-containear {
  background-color: #F2F3F5;
  padding-bottom: 80px;
  .title {
    font-size: 50px;
    padding-top: 80px;
    color: #434A63;
  }
  .product-box {
    width: 90vw;
    justify-content: space-between;
  }
  .product-item {
    display: inline-block;
    margin-bottom: 40px;
    text-align: center;
    width: 25%;
    margin-bottom: 40px;
    img {
      width: 180px;
      height: 180px;
      border-radius: 30px;
    }
    .product-price {
      margin-top: 4px;
    }
    .button-pay {
      width: 40px;
      height: 20px;
      background: #353961;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      margin: 10px auto;
    }
    .btn-enter {
      margin-top: 8px;
      cursor: pointer;
      color: #353961;
    }
  }
  .btn-more {
    width: 128px;
    height: 40px;
    cursor: pointer;
    border-radius: 8px 8px 8px 8px;
    opacity: 0.6;
    border: 1px solid #434A63;
    margin: 20px auto;
  }
}
.news-box {
  margin: 80px;
  margin-top: 80px;
  .btn {
    width: 128px;
    height: 40px;
    margin-top: 36px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid rgba(0,0,0,0.8);
  }
  .news-list {
    
    margin-left: 146px;
    border-top: 1px solid #E8E9EB;
    .news-item {
      padding: 40px 0;
      padding-left: 70px;
      border-bottom: 1px solid #E8E9EB;
      .title {
        margin-left: 36px;
      }
    }
  }
}
@media screen and (max-width: 889px) {
  .product-box {
    display: flex;
    flex-wrap: wrap;
  }
 .product-item {
  width: 370px !important;
 }
}
@media screen and (max-width: 820px) {
  .product-box {
    display: flex;
    flex-wrap: wrap;
  }
 .product-item {
  width: 320px !important;
 }
}
@media screen and (max-width: 670px) {
  .product-item {
   width: 200px !important;
  }
 }
 @media screen and (max-width: 445px) {
  .product-box {
    justify-content: center !important;
  }
 }
</style>