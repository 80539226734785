<template>
    <div>
      <div class="swiper-container pos-rel">
        <div class="swiper-wrapper">
          <div class="swiper-slide pos-rel">
            <!-- <img src="../assets/images/service_banner.png" alt=""> -->
            <div class="text-box">
              <p class="title">小可智能服务</p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="left-menu padding-40-80 font24">
            <p 
            @click="handlerMenu(index)"
            v-for="(item, index) in navList" :key="index"
            :class="[menuIndex == index ?'active':'']">{{ item.name }}</p>
        </div>
        <div class="right-content bg-w padding-40-80 flex-1">
            <p class="font24">{{ navList[menuIndex]['name'] }}</p>
            <div 
            class="margin-t-16 content"
            v-html="content.content">
                
            </div>
        </div>
      </div>
    </div>
</template>
<script>
import http from '@/request';
export default {
  name: 'App',
  data () {
    return {
        navList: [
            { name: '服务政策', type: 'SERVER_POLICY' }, { name: '常见问题', type: 'SERVER_QUESTION' }
        ],
        bannerList: [],
        productList: [],
        newsList: [],
        menuIndex: 0,
        content: {}
    }
  },
  mounted () {
    this.menuIndex = this.$route.params.index
    this.getArticleDetail();
    
  },
  methods: {
    handlerMenu (index) {
        this.menuIndex = index;
        this.getArticleDetail();
    },
    getArticleDetail () {
        http.get('/contentInfo', {
            type: this.navList[this.menuIndex]['type']
        }).then((res) => {
            res.content = res.content.replace(/<img/g,'<img style="width: 100%"')
            this.content = res;
        })
    },
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 630px) {
  .swiper-container {
    .title {
      font-size: 30px;
    }
  }
  .right-content {
    padding: 20px !important;
  }
}
@media screen and (max-width: 750px) {
  .left-menu {
    flex: 1;
    min-width: 206px !important;
  }
}
.left-menu {
    color: #767E98;
    width: 216px !important;
    cursor: pointer;
    background-color: #F2F3F5;
    p {
        padding: 24px 0 26px 0;
        border-bottom: 1px solid #DCDFE5;
    }
    .active {
        color: #353961;
        font-weight: bold;
        border-color: #353961;
    }
}
.right-content {
  min-width: 200px;
 
}
.content {
  img {
    width: 100% !important;
  }
}
.swiper-container {
  width: 100%;
  height: 50vw;
  font-size: 0;
  overflow: hidden;
  .swiper-wrapper {
    width: 100%;
  }
  .switch-box {
    position: absolute;
    right: 90px;
    bottom: 20px;
    z-index: 2;
    font-size: 14px;
    div {
      width: 40px;
      height: 40px;
      border-radius: 11px 11px 11px 11px;
      opacity: 1;
      border: 1px solid #434A63;
      margin-right: 12px;
      color: #434A63;
      cursor: pointer;
    }
  }
  .swiper-slide {
    background: url('../assets/images/service_banner.png') center center no-repeat;
    width: 100% !important;
    background-size: 100% 100%!important;
    height: 100%!important;
    .text-box {
      top: 0;
      left: 0;
      z-index: 2;
      font-size: 68px;
      position: absolute;
      padding: 10vw 0 0 12vw;
    }
    .title {
      font-size: 68px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>