<template>
    <div>
      <div class="swiper-container pos-rel">
        <div class="swiper-wrapper">
          <div class="swiper-slide pos-rel">
            <!-- <img src="../assets/images/news_banner.png" alt=""> -->
            <div class="text-box flex flex-center flex-column">
              <p class="title flex flex-center">新闻中心</p>
              <p class="sub-title font30">匠心产品，副标题或者说明文案</p>
            </div>
          </div>
        </div>
        <!-- <div class="switch-box flex">
          <div class="flex flex-center btn-prev">《</div>
          <div class="flex flex-center btn-next">》</div>
        </div> -->
      </div>
      <div class="">
        <div class="news-list flex flex-wrap w90vw">
          <div  @click="goPage('/newsDetail', item, index)"
          v-for="(item, index) in newsList" :key="index"
          class="news-item flex flex-align-center flex-column pointer">
              <img :src="item.imgUrl" alt="">
              <p class="font20 line2">{{ item.title }}</p>
              <span class="time">{{ item.createTime }}</span>
          </div>
          <div 
          v-if="newsList.length%4 == 1"
          class="news-item size-box"></div>
        </div>
        <div class="flex flex-center button-box">
          <el-pagination
            @current-change="handlerChange"
            @prev-click="handlerChange"
            @next-click="handlerChange"
            :page-size="12"
            layout="prev, pager, next"
            :total="totalData">
          </el-pagination>
        </div>
      </div>
    </div>
</template>
<script>
import http from '@/request';
import Swiper from 'swiper'
export default {
  name: 'App',
  data () {
    return {
        navList: [
            { name: '首页' }, { name: '品牌' }, { name: '产品' }, { name: '商城' },{ name: '联系我们' }
        ],
        bannerList: [],
        productList: [],
        newsList: [],
        current: 1,
        totalData: 0
    }
  },
  mounted () {
    this.getNewsList()
  },
  methods: {
    goPage (path, item, index) {
      this.$router.push({ name: 'newsDetail', query: {
        id: item.id,
        currentIndex: index,
        pageIndex: this.current
      } })
    },
    handlerChange (e) {
      this.current = e;
      this.getNewsList();
    },  
    getNewsList () {
      http.get('/newsList', {
        current: this.current,
        size: 12
      }).then((res) => {
        this.newsList = res.news;
        this.totalData = res.totalData;
        document.body.scrollTop = 0
       // firefox
        document.documentElement.scrollTop = 0
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.w90vw {
  width: 90vw;
  margin: 0 auto;
}
@media screen and (max-width: 900px) {
  .news-item {
    width: 48% !important;
    margin: 0 auto !important;
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 810px) {
  .news-item {
    
    margin: 0 auto !important;
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 630px) {
  .news-item {
    width: 90% !important;
    margin: 0 auto !important;
    margin-bottom: 20px !important;
  }
  .text-box {
    padding-top: 30px !important;
  }
}
.button-box {
  margin-bottom: 40px;
  cursor: pointer;
  .btn-prev {
    margin-right: 20px;
  }
  .btn-next {
    margin-left: 20px;
  }
  p {
    color: #9BA1B4;
    font-size: 20px;
    margin-right: 10px;
    &.active {
      color: #434A63;
    }
  }
 
}
.swiper-container {
  width: 100%;
  height: 25vw;
  font-size: 0;
  overflow: hidden;
  .swiper-wrapper {
    width: 100%;
  }
  .switch-box {
    position: absolute;
    right: 90px;
    bottom: 20px;
    z-index: 2;
    font-size: 14px;
    div {
      width: 40px;
      height: 40px;
      border-radius: 11px 11px 11px 11px;
      opacity: 1;
      border: 1px solid #434A63;
      margin-right: 12px;
      color: #434A63;
      cursor: pointer;
    }
  }
  .swiper-slide {
    background: url('../assets/images/news_banner.png') center center no-repeat;
    width: 100% !important;
    background-size: 100% 100%!important;
    height: 100%!important;
    .title {
        font-size: 68px;
    }
    .text-box {
      top: 0;
      left: 0;
      z-index: 2;
      font-size: 68px;
      position: absolute;
      padding: 10vw 0 0 12vw;
    }
    .sub-title {
      color: #323232;
    }
    .btn {
      width: 160px;
      height: 42px;
      font-size: 14px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      margin-top: 30px;
      border: 1px solid #FFFFFF;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.news-list {
    padding-top: 40px;
    justify-content: space-between;
    .size-box {
      opacity: 0;
    }
    .news-item {
        width: 32%;
        padding-bottom: 20px;
        background: #F9FAFC;
        border-radius: 12px 12px 12px 12px;
        overflow: hidden;
        margin-bottom: 40px;

        img {
            width: 100%;
            
        }
        p {
            padding: 0px 30px;
            margin: 20px 0 10px 0;
        }
        .time {
          color: #767E98;
          margin: 0 auto;
        }
    }
}
.line2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 设置最大显示行数 */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
</style>