<template>
    <div @click="handlerClose">
        <div class="service-wrapper flex flex-align-center flex-column">
            <p class="color-w flex flex-center font50 title">服务与支持</p>
            <ul class="flex flex-wrap  service-box">
                <li 
                @click="goPage('/service/0')"
                class="flex flex-align-center flex-1 flex-space-center">
                    <img src="../assets/images/icon_fw.png" alt="">
                    <p class="color-w font24">服务政策</p>
                </li>
                <li 
                @click="goPage('/service/1')"
                class="flex flex-align-center flex-1 flex-space-center">
                    <img src="../assets/images/icon_wt.png" alt="">
                    <p class="color-w font24">常见问题</p>
                </li>
                <li 
                @click="goPage('/contactUs')"
                class="flex flex-align-center flex-1 flex-space-center">
                    <img src="../assets/images/icon_kf.png" alt="">
                    <p class="color-w font24">联系我们</p>
                </li>
            </ul>
        </div>




        <div class="nav-wrapper flex flex-align-center flex-column w90vw">
            <img src="../assets/images/fotter_logo.png" alt="">
            <ul class="margin-t-80 nav-ul">
                <li class="nav-li-title flex-1" v-for="(item, index) in pageNav" :key="index">
                  <p class="font20 f-600">{{ item.name }}</p>
                  <ul class="margin-t-16 color-323232 font16">
                    <li
                    @click="goPage(child.path)"
                    v-for="(child, idx) in item.list" :key="idx"
                    >{{ child.name }}</li>
                  </ul>
                </li>
            </ul>
        </div>
        <div class="bg-30324B">
            <div class="copy-wrapper flex flex-align-center flex-column w90vw">
                <div class="margin-t-80">
                    <ul class="flex font16 color-w nav-ul flex-wrap">
                        <li 
                        @click="goPage(item.path)"
                        :class="index < 2 ? 'border-r':''"
                        v-for="(item, index) in navList" :key="index">{{ item.name }}</li>
                    </ul>
                    <ul class="image-box flex flex-center flex-wrap">
                       <li 
                       class="pos-rel"
                       @click.stop="handlerImg(item, index)"
                       v-for="(item, index) in imgList" :key="index">
                            <div class="code" v-if="item.type == 'IMG' && imgIndex == index">
                                <img :src="item.action" alt="">
                            </div>
                            <img 
                            class="icon"
                            :src="item.mediaLogo" alt="">
                       </li>
                    </ul>
                </div>
               <div class="margin-t-80 flex flex-center flex-column" style="opacity: 0.9">
                    <p 
                    @click="openWin"
                    class="font12 color-w pointer">Copyright 2021.Dianke All rights reserved.{{ webSite.icp }}</p>
                    <!-- <p class="font14 color-w margin-t-8"></p>
                    <p class="font14 color-w margin-t-8">备案信息备案信息备案信息</p>
                    <p class="font14 color-w margin-t-8">备案信息备案信息备案信息备案信息备案信息</p>
                    <p class="font14 color-w margin-t-8">公司名称医疗有限公司 公司地址公司地址公司地址 公司电话：0000-800000000</p>
                    <p class="font14 color-w margin-t-8">其他信息  其他信息</p>
                    <p class="font14 color-w margin-t-8">以上服务热线只针对小可智能官方发布产品</p> -->
               </div>
            </div>
        </div>
    </div>
</template>
<script>
import http from '@/request';
export default {
  name: 'App',
  data () {
    return {
        navList: [
            { name: '隐私声明', path: '/provincy' }, { name: '法律声明' }, { name: '小可智能' }
        ],
        webSite: {},
        imgList: [],
        pageNav: [
            {
                name: '匠心产品',
                list: [ { name: '小可智能V1',path:'/productList' } ]
            },
            {
                name: '关于我们',
                list: [ 
                    { name: '资讯动态',path:'/newsList' },{ name: '关于小可智能',path:'/brand' }, { name: '版权声明',path:'/provincy' },
                    { name: '使用协议',path:'/provincy' },{ name: '隐私政策',path:'/provincy' }, { name: '加入我们',path:'/job' },
                    { name: '联系我们',path:'/contactUs' }
                ]
            },
            {
                name: '服务与支持',
                list: [ 
                    { name: '服务政策',path:'/service/0' },{ name: '售后说明',path:'/service/0' }, { name: '常见问题',path:'/service/1' }
                ]
            },
            {
                name: '官方商城',
                list: [ 
                    { name: '京东旗舰店',path:'/shoppingMall' }
                ]
            },
            {
                name: '招商',
                list: [ 
                    { name: '招商政策',path:'/policy' }
                ]
            },
            {
                name: '友情链接',
                list: [
                    { name: '点可物联官网',path:'SPE' }
                ]
            },
        ],
        imgIndex: null
    }
  },
  mounted () {
    http.get('/webSite').then((res) => {
        this.webSite = res;
    })
    http.get('/footer').then((res) => {
        this.imgList = res;
    })
  },
  methods: {
    goPage (path) {
        if (!path) return
        if (path !== 'SPE'){
          this.$router.push({ path: path })
        } else {
          window.open("https://dianke-aiot.com");
        }

    },
    handlerClose () {
        this.imgIndex = null;
    },
    handlerImg (item, index) {
        if (item.type == 'LINK') {
            window.open(item.action, '_target')
        } else {
            if (this.imgIndex != null) this.imgIndex = null;
            else this.imgIndex = index;
        }
    },
    openWin () {
        window.open('https://beian.miit.gov.cn/', '_target')
    },
    contactUs () {
        window.open('https://work.weixin.qq.com/kfid/kfcf0be33cc4d920fe2', '_target')
    }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 700px) {
    .service-wrapper {
        background-color: #30324B;
        .title {
            font-size: 40px !important;
        }
        ul {
            width: 80vw;
            margin-top: 70px;
            li {
                min-width: 180px;
                margin: 20px auto;
                img {
                    margin-right: 19px;
                }
            }
        }
    }
    .nav-ul li:nth-child(4) {
        display: none;
    }
  }
  @media screen and (max-width: 450px) {
    .image-box {
        justify-content: space-around !important;
        img {
            width: 30px !important;
            height: 30px !important;
        }
    }
    .nav-wrapper {
        img {
            margin-top: 20px !important;
        }
        .nav-ul {
            margin-top: 20px !important;
            .nav-li-title {
                width: 50% !important;
              }
        }
    }
    .service-wrapper {
       padding: 10px !important;
    }
    .service-box {
        margin-top: 20px !important;
    }
  }
.service-wrapper {
    padding: 80px 0;
    background-color: #30324B;
    ul {
        width: 80vw;
        margin-top: 70px;
        li {
            cursor: pointer;
            min-width: 180px;
            img {
                margin-right: 19px;
            }
        }
    }
}
.nav-wrapper {
    width: 90vw;
    min-height: 522px;
    img {
        margin-top: 80px;
    }
    .nav-ul {
        width: 100%;
        
        .nav-li-title {
            width: 16%;
            cursor: pointer;
            vertical-align: text-top;
            flex: 1;
            display: inline-block;
            min-width: 140px;
            margin-bottom: 20px;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            li {
                margin-top: 8px;
            }
        }
    }
}
.copy-wrapper {
   height: auto;
   padding-bottom: 10px;
    background-color: #30324B;
    .nav-ul {
        width: 100%;
        li {
            cursor: pointer;
            padding: 0 20px;
            margin-bottom: 20px;
        }

        .border-r {
            border-right: 1px solid #fff;
        }
    }
    .image-box {
        width: 100%;
        margin-top: 50px;
        justify-content: space-between;
        li {
            margin: 0 5px;
        }
        .code {
            position: absolute;
            top: -200px;
            img {
                width: 200px;
                height: 200px;
                
            }
        }
    }
}
</style>