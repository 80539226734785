<template>
    <div>
      <div class="swiper-container pos-rel">
        <div class="swiper-wrapper">
          <div class="swiper-slide pos-rel">
            <!-- <img src="../assets/images/job_banner.png" alt=""> -->
            <!-- <div class="text-box">
              <p class="sub-title font24 f-b">小可智能副标题</p>
              <p class="title f-600">小可智能欢迎你的加入</p>
              <p class="sub-title font18">这里是进一步说明的文案</p>
            </div> -->
          </div>
        </div>
      </div>
      <div class="padding-80-0 w90vw flex flex-wrap flex-space-center job-intro">
        <div class="intro-text flex-1">
            <p class="font32 f-b">人才理念</p>
            <p class="margin-t-40 font20 f-500">以人为本 量才适用 创新超越 共同成功。</p>
        </div>
        <img class="w560" src="../assets/images/job_img.png" alt="">
      </div>
     <div v-for="(item, index) in jobList" :key="index">
        <div  :class="[index%2 == 0 ? 'bg-F9FAFC':'']">
          <div class="job-item w90vw flex flex-column"
          @mouseenter="handlerSend(index)"
          @mouseleave="handlerSend(index)"
          >
              <div>
                <p class="font32 f-600 job-name">{{ item.name }}</p>
            <div class="flex margin-t-40 flex-space-between tag-item flex-wrap">
                <p  class="font20 color-323232 flex-1">招聘类别：<span class="color-000 f-500">{{ item.type }}</span> </p>
                <p  class="font20 color-323232 flex-1">工作性质：<span class="color-000 f-500">{{ item.jobNature ? '兼职':'全职' }}</span> </p>
                <p  class="font20 color-323232 flex-1">薪资范围：<span class="color-000 f-500">{{ item.wage }}</span> </p>
                <p  class="font20 color-323232 flex-1">招聘人数：<span class="color-000 f-500">{{ item.num }}</span> </p>
            </div>
            <div class="flex flex-space-between tag-item flex-wrap">
                <p  class="font20 color-323232 flex-1">发布时间：<span class="color-000 f-500"></span> </p>
                <p  class="font20 color-323232 flex-1">截止时间：<span class="color-000 f-500"></span> </p>
                <p  class="font20 color-323232 flex-1">工作地点：<span class="color-000 f-500">{{ item.address }}</span> </p>
                <p class="flex-1"></p>
            </div>
            <div class="job-content margin-t-40" v-if="item.checked" v-html="item.content"></div>
            <div class="flex flex-align-center margin-t-40">
                <div 
                @click="handlerSend(index)"
                class="button-send color-w font20 f-b flex flex-center pointer">
                    投递该岗位
                </div>
                <p v-if="item.checked" class="font20 f-500">
                    投递至邮箱：<span class="color-323A85">ying.zhao@dianke-aiot.com</span>
                </p>
            </div>
              </div>
          </div>
        </div>
     </div>
    </div>
</template>
<script>
import http from '@/request';
import Swiper from 'swiper'
export default {
  name: 'App',
  data () {
    return {
        jobList: []
    }
  },
  mounted () {
    this.getJobList()
  },
  methods: {
    handlerSend (index) {
        let flag = this.jobList[index]['checked']
        this.jobList.map((item) => {
            item.checked = false;
        })
        this.jobList[index]['checked'] = !flag;
    },
    getJobList () {
      http.get('/joinUsInfo').then((res) => {
        res.map((item) => {
            item.checked = false;
        })
        this.jobList = res;
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.tag-item p {
  min-width: 220px;
  margin-bottom: 20px;
}
.intro-text {
  margin-bottom: 20px;
  margin-right: 80px;
  min-width: 350px;
}
.button-send {
    width: 160px;
    height: 60px;
    background: #353961;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-right: 30px;
}
.job-item {
    padding: 80px 0px;
    align-items: center;
}
.w560 {
    width: 560px;
    border-radius: 80px;
}
.margin-80-160 {
    margin: 80px 160px;
}
.swiper-container {
  width: 100%;
  height: 50vw;
  font-size: 0;
  overflow: hidden;
  .swiper-wrapper {
    width: 100%;
  }
  .swiper-slide {
    background: url('../assets/images/11111.jpg') center center no-repeat;
    width: 100% !important;
    background-size: 100% 100%!important;
    height: 100%!important;
    .title {
        margin: 30px 0;
        font-size: 60px;
    }
    .text-box {
      top: 0;
      left: 0;
      z-index: 2;
      font-size: 68px;
      position: absolute;
      padding: 120px 0 0 120px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
.padding-80-0 {
  padding: 80px 0px;
}
.job-content {
  max-width: 880px;
}
@media screen and (max-width: 800px) {
  .job-item {
    padding: 20px 10px !important;
  }
  .button-send {
    width: 80px !important;
    height: 40px !important;
    font-size: 12px !important;
  }
  .padding-80-0 {
    padding: 20px 0 !important;
  }
  .job-intro {
    text-align: center;
    .intro-text {
      margin-right: 0;
    }
    img {
      width: 80%;
      border-radius: 40px;
    }
  }
  .job-name {
    text-align: center;
  }

}
@media screen and (max-width: 400px) {
  .job-intro {
    padding: 10px !important;

  }
  .job-item {
    padding: 20px 6px !important;
  }
  .tag-item {
    p {
      min-width: 180px !important;
    }
  }
}
</style>