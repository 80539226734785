<template>
    <div>
      <div class="swiper-container pos-rel">
        <div class="swiper-wrapper">
          <!-- background: url(&quot;https://dk-official.oss-cn-hangzhou.aliyuncs.com/banner/banner-2.png&quot;) center center no-repeat; -->
          <el-carousel  :interval="4000" height="100%" ref="carousel" indicator-position="none">
            <el-carousel-item  v-for="(item, index) in bannerList" :key="index">>
               <div 
               :style="{background: 'url(' + item.bannerImg + ')' + 'center center no-repeat'}"
               class="swiper-slide pos-rel" @click="goUrl(item)">
               <!-- :style="{background: 'url(' + item.bannerImg + ')' + 'center center no-repeat'}" -->
              
                <!-- <img :src="item.bannerImg" alt=""> -->
                <div class="text-box color-w" v-if="item.showMore == 'YES'">
                  <p class="title">{{ item.bannerTitle }}</p>
                  <p class="sub-title" v-for="(text, idx) in item.bannerText" :key="idx">{{ text }}</p>
                  <div 
                  
                  class="btn flex flex-center">查看更多</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
         
        </div>
        <div class="switch-box flex">
          <div 
          @click="handlerSwitch('prev')"
          class="flex flex-center btn-prev swiper-button-prev"><img style="width:10px;height:10px" src="../assets/images/arrow_l.png" alt=""></div>
          <div 
          @click="handlerSwitch('next')"
          class="flex flex-center btn-next swiper-button-next"><img style="width:10px;height:10px" src="../assets/images/arrow_r.png" alt=""></div>
        </div>
      </div>
      <div class="w90vw product-top" style="margin-top: 4vw">
        <div @click="goDianke">
          <img style="width: 100%" src="../assets/images/haomibo.png" alt="">
        </div>
        <img style="width: 100%;margin-top:3vw;" src="../assets/images/product1.png" alt="">
      </div>
      <div class="w90vw product-top-info">
        <div class="flex flex-center font50 title color-434A63">匠心产品</div>
        <div class="flex margin-t-40 flex-space-between flex-wrap product-box-top">
          <div class="product-top-info-text">
            <p class="font24">一键呼救报警器</p>
            <p class="font32 margin-t-32">小可智能守护精灵</p>
<!--            <p class="font16 margin-t-40">这里是进一步说明的文案</p>-->
            <div 
            @click="goDetail(productList[0])"
            class="pointer button-detail flex flex-center font16 margin-t-60">点击查看详情</div>
          </div>
          <img src="../assets/images/product3.png" class="border-r-80" alt="">
        </div>
      </div>
      <div class="product-containear">
        <div class="flex flex-center font-50 title">产品列表</div>
        <div  class="margin-t-80 flex flex-center">
          <div class="product-box">
            <div  
            @click="goDetail(item)"
            class="flex flex-center flex-column product-item pointer" v-for="(item, index) in productList" :key="index">
              <img class="product-img" :src="item.mallImg" alt="">
              <p class="f-600 font20 margin-t-16">{{ item.name }}</p>
              <p class="font12 product-price">RMB {{ item.price }}</p>
              <div 
              @click.stop="goPage('/shoppingMall')"
              class="button-pay flex flex-center font12 color-w pointer">购买</div>
              <p 
             
              class="btn-enter font12">进一步了解 
                <img style="width:10px;height:10px" src="../assets/images/arrow_r.png" alt="">
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="news-containear">
        <div class="flex flex-center font50 title margin-t-80 color-434A63">企业新闻</div>
        <div class="news-box w90vw flex flex-wrap flex-space-center">
          <div class="">
            <p class="f-600 font32">新闻发布</p>
            <div 
            @click="goPage('/newsList')"
            class="btn flex flex-center font14 pointer">查看更多</div>
          </div>
          <div class="news-list">
            <div 
            @click="goNews(item, index)"
            class="news-item flex font16 pointer" v-for="(item, index) in newsList" :key="index">
              <span>{{ item.createTime }}</span>
              <span class="news-title">
                {{ item.title }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import http from '@/request';
import Swiper from 'swiper'
export default {
  name: 'App',
  data () {
    return {
        navList: [
            { name: '首页' }, { name: '品牌' }, { name: '产品' }, { name: '商城' },{ name: '联系我们' }
        ],
        bannerList: [],
        productList: [],
        newsList: [],
        mySwiper: null,
        timer: null
    }
  },
  mounted () {
  
    this.getHomeBanner();
    this.getProductList();
    this.getNewsList()
    // window.addEventListener('resize', function () {
    //   this.mySwiper = new Swiper('.swiper-container', {
    //       autoplay: true,//可选选项，自动滑动
    //       loop: true
    //     })
    // })
  },
  methods: {
    goUrl (item) {
      if (!item.bannerUrl) return
      window.open(item.bannerUrl, '_target')
    },
    goNews (item, index) {
      this.$router.push({ name: 'newsDetail', query: {
        id: item.id,
        currentIndex: index,
        pageIndex: 1
      } })
    },
    goPage (path) {
        this.$router.push({ path: path })
    },
    goDetail (item) {
      this.$router.push({ name: 'productDetail', query: {
        id: item.id,
        name: item.name
      } })
    },
    goDianke(){
      window.open("https://dianke-aiot.com")
    },
    handlerSwitch (type) {
      console.log('type', type)
      if (type == 'next') this.$refs.carousel.next();
      else this.$refs.carousel.prev();
    },
    getHomeBanner () {
      http.get('/banner').then((res) => {

        // this.mySwiper = new Swiper('.swiper-container', {
        //   autoplay: true,//可选选项，自动滑动
        //   loop: true
        // })
        this.bannerList = res;
        
      })
      if (this.timer != null) clearInterval(this.timer)
      this.timer = setInterval(() => {

        // this.mySwiper.slideNext();
      }, 3000)
    },
    getProductList () {
      http.get('/homeProductList').then((res) => {
        this.productList = res;
        
      })
    },
    getNewsList () {
      http.get('/homeNewsList').then((res) => {
        this.newsList = res;
        
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-carousel {
  width: 100%;
  height: 50vw !important;
}
.el-carousel__container {
  height: 100%;
}
.button-detail {
  width: 220px;
  height: 42px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 2px solid #000000;
  margin-bottom: 20px;
}
.product-top-info {
  margin: 100px auto;
}
.product-top-info-text {
  margin-top: 100px;
}
.swiper-container {
  width: 100%;
  height: 50vw;
  font-size: 0;
  overflow: hidden;
  .swiper-wrapper {
    width: 100%;
  }
  .switch-box {
    position: absolute;
    right: 90px;
    bottom: 100px;
    z-index: 999999;
    font-size: 14px;
    div {
      width: 40px;
      height: 40px;
      border-radius: 11px 11px 11px 11px;
      opacity: 1;
      border: 1px solid #434A63;
      margin-right: 12px;
      color: #434A63;
      cursor: pointer;
    }
  }
  .swiper-slide {
    width: 100% !important;
    background-size: 100% 100%!important;
    height: 100%!important;
    cursor: pointer;
    .text-box {
      top: 0;
      left: 0;
      z-index: 2;
      font-size: 68px;
      position: absolute;
      padding: 10vw 0 0 12vw;
    }
    .title {
      font-size: 68px;
    }
    .sub-title {
      font-size: 30px;
      opacity: 0.8;
    }
    .btn {
      width: 160px;
      height: 42px;
      font-size: 14px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      margin-top: 30px;
      border: 1px solid #FFFFFF;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.product-containear {
  background-color: #F2F3F5;
  .title {
    font-size: 50px;
    padding-top: 80px;
    color: #434A63;
  }
  .product-box {
    width: 90vw;
    margin: 0 auto;
    justify-content: space-between;
  }
  .product-item {
    display: inline-block;
    margin-bottom: 40px;
    text-align: center;
    width: 25%;
    img {
      width: 180px;
      height: 180px;
      border-radius: 30px;
    }
    .product-price {
      margin-top: 4px;
    }
    .button-pay {
      width: 40px;
      height: 20px;
      background: #353961;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      margin: 10px auto;
    }
    .btn-enter {
      cursor: pointer;
      margin-top: 8px;
      color: #353961;
    }
  }
}
.news-box {
  margin-top: 80px;
  .btn {
    width: 128px;
    height: 40px;
    margin-top: 36px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid rgba(0,0,0,0.8);
  }
  .news-list {
    
    margin-left: 6vw;
    border-top: 1px solid #E8E9EB;
    .news-item {
      padding: 40px 0;
      padding-left: 70px;
      align-items: center;
      border-bottom: 1px solid #E8E9EB;
      .news-title {
        margin-left: 36px;
      }
    }
    .news-item:hover{
      color: #0063F5;
    }
  }
}
@media screen and (max-width: 860px) {
  .text-box {
    font-size: 50px !important;
  }
  .sub-title {
    font-size: 30px !important;
  }
  .news-box {
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
  .news-list {
    width: 500px;
    margin-top: 20px;
    margin-left: 0 !important;
  }
  .news-item {
    padding: 20px 10px !important;
    font-size: 14px !important;
  }
}
@media screen and (max-width: 550px) {
  
  .news-list {
    width: 300px;
    margin-top: 20px;
    margin-left: 0 !important;
  }
  .news-item {
    padding: 20px 10px !important;
    font-size: 12px !important;
  }
}
@media screen and (max-width: 700px) {
  .product-top-info {
    margin: 20px auto !important;
  }
  .product-top-info-text {
    margin-top: 0px !important;
  }
  .text-box {
    font-size: 30px !important;
  }
  .sub-title {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 889px) {
  .product-box {
    display: flex;
    flex-wrap: wrap;
  }
 .product-item {
  width: 370px !important;
 }
}
@media screen and (max-width: 820px) {
  .product-box {
    display: flex;
    flex-wrap: wrap;
  }
 .product-item {
  width: 320px !important;
 }
}
@media screen and (max-width: 670px) {
  .product-item {
   width: 200px !important;
  }
 }
 @media screen and (max-width: 445px) {
  .product-box {
    justify-content: center !important;
  }
  .product-box-top {
    img {
      width: 100%;
      border-radius: 60px;
    }
  }
 }
 @media screen and (max-width: 1246px) {
  .product-box-top {
    justify-content: center !important;
  }
 }
</style>