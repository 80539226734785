<template>
    <div>
      <div class="swiper-container pos-rel">
        <div class="swiper-wrapper">
          <div class="swiper-slide pos-rel">
            <img src="../assets/images/service_banner.png" alt="">
            <div class="text-box">
              <p class="title">小可智能商城</p>
              <p class="sub-title font30">小可智能守护精灵已在京东商城上线</p>
            </div>
            <div class="code-box flex flex-column" >
              <p class="font16 flex flex-center">用微信或京东扫一扫进入</p>
              <p class="font20 flex flex-center f-600">小可智能京东自营官方旗舰店</p>
              <img src="../assets/images/code.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="product-containear">
        <div class="w90vw">
          <div class="flex flex-center font-50 title color-434A63" id="box1">一键呼救报警器</div>
          <div  class="margin-t-80 flex">
            <div class="product-box">
              <div 
              @click="goDetail(item)"
              class="flex flex-center flex-column product-item pointer" v-for="(item, index) in productList" :key="index">
                <img class="product-img" :src="item.mallImg" alt="">
                <p class="f-600 font20 margin-t-16">{{ item.name }}</p>
                <p class="font12 product-price">RMB {{ item.price }}</p>
                <div @click.stop="goPage" class="button-pay flex flex-center font12 color-w pointer">去京东购买</div>
                <p class="btn-enter font12">进一步了解 <img style="width:10px;height:10px" src="../assets/images/arrow_r.png" alt=""></p>
              </div>
            </div>
          </div>
          <div 
          class="flex flex-center font14 color-434A63">
              <el-pagination
              @current-change="handlerChange"
              @prev-click="handlerChange"
              @next-click="handlerChange"
              :page-size="8"
              layout="prev, pager, next"
              :total="goodsTotal">
            </el-pagination>
          </div>
          <div class="flex flex-center font-50 title color-434A63" id="box2">24小时人工服务</div>
          <div  class="margin-t-60 flex">
            <div class="product-box">
              <div  
              @click="goDetail(item)"
              class="flex flex-center flex-column product-item pointer" v-for="(item, index) in serviceList" :key="index">
                <img class="product-img" :src="item.mallImg" alt="">
                <p class="f-600 font20 margin-t-16">{{ item.name }}</p>
                <p class="font12 product-price">RMB {{ item.price }}</p>
                <div @click="goPage" class="button-pay flex flex-center font12 color-w pointer">去京东购买</div>
                <p class="btn-enter font12">进一步了解 <img style="width:10px;height:10px" src="../assets/images/arrow_r.png" alt=""></p>
              </div>
            </div>
          </div>
          <div 
          class="flex flex-center font14 color-434A63">
              <el-pagination
              @current-change="handlerChange1"
              @prev-click="handlerChange1"
              @next-click="handlerChange1"
              :page-size="8"
              layout="prev, pager, next"
              :total="serviceTotal">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import http from '@/request';
import Swiper from 'swiper'
export default {
  name: 'App',
  data () {
    return {
        productList: [],
        serviceList: [],
        goodsTotal: 1,
        serviceTotal: 1,
        goodsPage: 1,
        servicePage: 1,
    }
  },
  mounted () {
    this.getProductList();
    this.getServiceList()
  },
  methods: {
    handlerChange (e) {
      this.goodsPage = e;
      this.getProductList()
      document.body.scrollTop = document.getElementById('box1').offsetTop
       // firefox
        document.documentElement.scrollTop = document.getElementById('box1').offsetTop
    },
    handlerChange1 (e) {
      this.servicePage = e;
      this.getServiceList()
      document.body.scrollTop = document.getElementById('box2').offsetTop
       // firefox
        document.documentElement.scrollTop = document.getElementById('box2').offsetTop
    },
    goPage () {
        window.open('https://item.jd.com/10094651229490.html', '_target')
    },
    goDetail (item) {
      if (item.id !== '06a268693a23f49f660ffc816ea2289f'){
        this.$router.push({ name: 'productDetail', query: {
            id: item.id,
            name: item.name
          } })
      } else {
        window.open('https://dianke-aiot.com')
      }

      // this.$router.push({ path: '/productDetail' + `/${item.id}` })
    },
    getProductList () {
      http.get('/productList', {
        type: "CALL",
        current: this.goodsPage,
        size: 8
      }).then((res) => {
        this.productList = res.data;
        this.goodsTotal = res.totalData
      })
    },
    getServiceList () {
      http.get('/productList', {
        type: "SERVICE",
        current: this.servicePage,
        size: 8
      }).then((res) => {
        this.serviceList = res.data;
        this.serviceTota = res.totalData
      })
    }
  }
}
</script>
<style lang="scss" scoped>

@media screen and (max-width: 980px) {
  .swiper-container {
    .title {
      font-size: 3vw;
    }
    .sub-title {
      font-size: 2vw !important;
    }
  }
  .product-containear {
    .title {
      padding-top: 20px !important;
    }
  }
}
.code-box {
  padding: 24px 40px;
  background: #FFFFFF;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  position: absolute;
  right: 18vw;
  z-index: 2;
  top: 8vw;
  .font16 {
    font-size: 1vw;
  }
  .font20 {
    font-size: 1.2vw;
  }
  img {
    width: 18vw !important;
    height: 18vw !important;
  }
}
.swiper-container {
  width: 100%;
  height: 50vw;
  font-size: 0;
  overflow: hidden;
  .swiper-wrapper {
    width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
  
  .switch-box {
    position: absolute;
    right: 90px;
    bottom: 20px;
    z-index: 2;
    font-size: 14px;
    div {
      width: 40px;
      height: 40px;
      border-radius: 11px 11px 11px 11px;
      opacity: 1;
      border: 1px solid #434A63;
      margin-right: 12px;
      color: #434A63;
      cursor: pointer;
    }
  }
  .swiper-slide {
    .text-box {
      top: 0;
      left: 0;
      z-index: 2;
      font-size: 68px;
      position: absolute;
      padding: 10vw 0 0 12vw;
    }
    .sub-title {
      font-size: 40px;
      opacity: 0.8;
    }
    .btn {
      width: 160px;
      height: 42px;
      font-size: 14px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      margin-top: 30px;
      border: 1px solid #FFFFFF;
    }
  }
}
.product-containear {
  background-color: #F2F3F5;
  padding-bottom: 80px;
  .title {
    font-size: 50px;
    padding-top: 80px;
    color: #434A63;
  }
  .product-box {
    width: 90vw;
    justify-content: space-between;
  }
  .product-item {
    display: inline-block;
    margin-bottom: 40px;
    text-align: center;
    width: 25%;
    img {
      width: 180px;
      height: 180px;
      border-radius: 30px;
    }
    .product-price {
      margin-top: 4px;
    }
    .button-pay {
      width: 76px;
      height: 20px;
      background: #353961;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      margin: 10px auto;
    }
    .btn-enter {
      cursor: pointer;
      margin-top: 8px;
      color: #353961;
    }
  }
  .btn-more {
    width: 128px;
    cursor: pointer;
    height: 40px;
    border-radius: 8px 8px 8px 8px;
    opacity: 0.6;
    border: 1px solid #434A63;
    margin: 20px auto;
    cursor: pointer;
  }
}
.news-box {
  margin: 80px;
  margin-top: 80px;
  .btn {
    width: 128px;
    height: 40px;
    margin-top: 36px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid rgba(0,0,0,0.8);
  }
  .news-list {
    
    margin-left: 146px;
    border-top: 1px solid #E8E9EB;
    .news-item {
      padding: 40px 0;
      padding-left: 70px;
      border-bottom: 1px solid #E8E9EB;
      .title {
        margin-left: 36px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .text-box {
    font-size: 30px !important;
    align-items: flex-start;
    padding-left: 40px !important;
  }
  .sub-title {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 889px) {
  .product-box {
    display: flex;
    flex-wrap: wrap;
  }
 .product-item {
  width: 370px !important;
 }
}
@media screen and (max-width: 820px) {
  .product-box {
    display: flex;
    flex-wrap: wrap;
  }
 .product-item {
  width: 320px !important;
 }
}
@media screen and (max-width: 670px) {
  .product-item {
   width: 200px !important;
  }
 }
 @media screen and (max-width: 500px) {
  .product-containear {
    .title {
      font-size: 40px !important;
    }
  }
  .code-box {
    top: 50px !important;
    border-radius: 4px !important;
    padding: 4px 10px !important;
    justify-content: center;
    right: 4vw;
    img {
      width: 100px !important;
      height: 100px !important;
      margin: 0 auto;
    }
    .font16, .font20 {
      font-size: 10px !important;
    }
  }
}
 @media screen and (max-width: 445px) {
  .product-box {
    justify-content: center !important;
  }
 }
</style>