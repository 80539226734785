<template>
    <div>
      <div class="top-banner pos-rel font50 color-w">
        {{ newsDetail.title }}
      </div>
      <div class="nav-bread flex flex-align-center flex-wrap font16 color-767E98">
        <a href="/"><span class="color-767E98">首页</span></a>   <span class="margin-0-10">/</span>
        <a href="/newsList"><span class="color-767E98">新闻中心</span></a>     <span class="margin-0-10">/</span>
        <span class="color-434A63"> {{ newsDetail.title }}</span>
      </div>
      <div class="detail-containear">
        <p class="font16 color-767E98">{{ newsDetail.createTime }}</p>
        <div v-html="newsDetail.content" class="margin-t-16">

        </div>
      </div>
      <div class="flex flex-center button-box">
            <div @click="handlerPre('pre')" class="flex flex-center">
              <p>上一篇</p>
              <div class="btn-page btn-prev"><img style="width:10px;height:10px" src="../assets/images/arrow_l.png" alt=""></div>
            </div>
            <p @click="goBack">返回列表</p>
            <div @click="handlerPre('next')" class="flex flex-center">
              <div class="btn-page btn-next"><img style="width:10px;height:10px" src="../assets/images/arrow_r.png" alt=""></div>
              <p>下一篇</p>
            </div>
      </div>
    </div>
</template>
<script>
import http from '@/request';
import Swiper from 'swiper'
export default {
  name: 'App',
  data () {
    return {
        newsList: [],
        newsDetail: [],
        query: {},
        currentIndex: 0,
        totalData: 0
    }
  },
  mounted () {
    this.query = this.$route.query
    this.currentIndex = this.query.currentIndex
    this.getNewsInfo(this.query.id);
    this.getNewsList()
  },
  methods: {
    handlerPre (type) {
      if ((this.currentIndex == 0 && type == 'pre' ) || (this.currentIndex >= this.newsList.length - 1 && type == 'next')) {
        if (this.currentIndex == 0 && type == 'pre') this.$message.warning('没有上一篇了');
        if (this.currentIndex >= this.newsList.length - 1 && type == 'next') this.$message.warning('没有下一篇了');
        return;
      }
      if (type == 'pre') this.currentIndex--;
      else this.currentIndex++;
      this.$router.push({ name: 'newsDetail', query: {
        id: this.newsList[this.currentIndex]['id'],
        currentIndex: this.currentIndex,
        pageIndex: 1
      } })
      this.getNewsInfo(this.newsList[this.currentIndex]['id'])
    },
    goBack () {
      this.$router.push({ path: '/newsList' })
    },
    getNewsInfo (id) {
     
      http.get('/newsInfo', {
        newsId: id
      }).then((res) => {
        document.title = res.title;
        this.newsDetail = res;
      })
    },
    getNewsList () {
      http.get('/newsList', {
        current: 1,
        size: 1000
      }).then((res) => {
        this.newsList = res.news;
        this.totalData = res.totalData;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 630px) {
  .button-box {
    p {
      font-size: 12px !important;
    }
  }
  .top-banner {
    padding: 20px !important;
    font-size: 30px !important;
  }
  .nav-bread {
    padding: 20px 10px !important;
    background-color: #FFFFFF;
  }
  .detail-containear {
    margin: 0 10px !important;
  }
}
.top-banner {
    padding: 80px;
    background-color: #30324B;
}
.nav-bread {
    padding: 40px 80px;
    background-color: #FFFFFF;
}
.detail-containear {
    margin: 0 80px;
    margin-bottom: 40px;
}
.button-box {
  margin-top: 10px;
  margin-bottom: 40px;
  cursor: pointer;
  .btn-prev {
    margin-right: 20px;
    margin-left: 12px;
  }
  .btn-next {
    margin-left: 20px;
    margin-right: 12px;
  }
  p {
    color: #9BA1B4;
    font-size: 20px;
    margin-right: 10px;
    &.active {
      color: #434A63;
    }
  }
 
}
</style>