<template>
    <div>
      <div class="swiper-container pos-rel">
        <div class="swiper-wrapper">
          <div class="swiper-slide pos-rel">
            <img src="../assets/images/contact_banner.png" alt="">
            <div class="text-box">
<!--              <p class="sub-title font24 f-b">小可智能欢迎你的加入</p>-->
              <p class="title f-600">联系我们</p>
              <p class="sub-title font18">如果有产品、招商及其他问题，请随时与我们联系</p>
            </div>
          </div>
        </div>
      </div>
      <div class="padding-80-0 flex w80vw flex-wrap flex-space-center flex-align-center">
        <div class="flex-1 intro-text">
            <p class="font28 ">服务热线</p>
            <p class=" font24 f-500">400-965-6822</p>
            <p class="font28 margin-t-48">服务邮箱</p>
            <p class=" font24 f-500">jian.yu@dianke-aiot.com</p>
            <p class="font28 margin-t-48">招商热线</p>
            <p class="font24 f-500">400-965-6822</p>
        </div>
        <img class="intro-img" src="../assets/images/contact_img.png" alt="">
      </div>
      <div  class="form-containear bg-F9FAFC">
        <div class="w80vw">
          <div class="flex flex-wrap form-wrapper">
            <div class="form-box">
                <p class="font32 f-600">填写表单，我们将尽快与您联系</p>
                <div class="margin-t-30">
                    <div class="form-item">
                        <p class="font20 f-b">您的姓名</p>
                        <input 
                        v-model="form.name"
                        class="" type="text" placeholder="请输入">
                    </div>
                    <div class="form-item margin-t-30">
                        <p class="font20 f-b">联系方式</p>
                        <input 
                        v-model="form.contactWay"
                        class="" type="text" maxlength="11" placeholder="请输入">
                    </div>
                    <div class="margin-t-40 flex">
                       <div 
                       @click="handlerType(index)"
                       class="flex flex-align-center radio-item" 
                       :class="[typeIndex == index ? 'active':'color-767E98']"
                       v-for="(item, index) in typeList" :key="index">
                            <div class="radio-box flex flex-center"></div>
                            <p 
                            
                            class="font20 f-b margin-l-12">{{ item.label }}</p>
                       </div>
                    </div>
                    <div v-if="typeIndex == 1">
                      <div class="form-item margin-t-30">
                          <p class="font20 f-b">公司名称</p>
                          <input 
                          v-model="form.enterpriseName"
                          class="" type="text" maxlength="11" placeholder="请输入">
                      </div>
                      <div class="form-item margin-t-30">
                          <p class="font20 f-b">您的职务</p>
                          <input 
                          v-model="form.job"
                          class="" type="text" maxlength="11" placeholder="请输入">
                      </div>
                    </div>
                    <div class="form-item margin-t-40">
                        <p class="font20 f-b">需求描述</p>
                        <textarea 
                        v-model="form.demand"
                        placeholder="请输入" name="" id="" cols="30" rows="10"></textarea>
                    </div>
                </div>
            </div>
            <div class="channel-form">
                <p class="font20 f-b">您从什么渠道了解到我们</p>
                <div>
                    <div 
                    @click="handlerChannel(index)"
                    class="flex flex-align-center radio-item margin-t-32" 
                    :class="[channelIndex == index ? 'active':'color-434A63']"
                    v-for="(item, index) in channelList" :key="index">
                         <div class="radio-box flex flex-center"></div>
                         <p 
                         
                         class="font20 f-b margin-l-12">{{ item }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div>
          <div @click="handlerAdd" class="button-send margin-t-30 color-w f-b font20 flex flex-center">确定提交</div>
          <div></div>
        </div>
        </div>
      </div>
    </div>
</template>
<script>
import http from '@/request';

export default {
  name: 'App',
  data () {
    return {
        jobList: [],
        typeIndex: 0,
        typeList: [{ label: '个人',type: 'PERSONAL' },{ label: '公司',type: 'ENTERPRISE' }],
        channelList: ['朋友推荐', '网络搜索', '社交媒体', '展会', '线上活动', '其他'],
        channelIndex: -1,
        form: {
            name: '',
            contactWay: '',
            userType: 'PERSONAL',
            enterpriseName: null,
            job: null,
            channel: '',
            demand: ''
        },
        seconds: 10,
        timer: null
    }
  },
  mounted () {
    
  },
  methods: {

    handlerAdd () {
        if (this.seconds != 10) return;
        if (!this.form.name) return this.$message.warning('请输入姓名');
        if (!this.form.contactWay) return this.$message.warning('请输入联系方式');
        if (!this.form.demand) return this.$message.warning('请输入需求描述');
        if (!this.form.channel) return this.$message.warning('请选择渠道');
        if (this.typeIndex == 1) {
          if (!this.form.enterpriseName) return this.$message.warning('请输入公司名称');
          if (!this.form.job) return this.$message.warning('请输入好您的职务');
        }
        this.seconds--;
        http.post('/addDemand', this.form).then((res) => {
          this.$message.success('提交成功');
          this.timer =  setInterval(() => {
            this.seconds--;
            if (this.seconds == 0) {
              clearInterval(this.timer);
              this.seconds = 10;
            }
          }, 1000)
        })
    },
    handlerType (index) {
       this.typeIndex = index;
       this.form.enterpriseName = '';
       this.form.job = ''
       this.form.userType = index === 0 ? 'PERSONAL' : 'ENTERPRISE'
    },
    handlerChannel (index) {
        this.channelIndex = index;
        this.form.channel = this.channelList[this.channelIndex]
    }
  }
}
</script>
<style lang="scss" scoped>
.w80vw {
  width: 70vw;
  margin: 0 auto;
}
@media screen and (max-width: 700px) {
  .intro-text {
    margin-right: 0 !important;
  }
  .padding-80-0 {
    padding: 20px 0 !important;
  }
}
@media screen and (max-width: 1234px) {
  .padding-80-0 {
    padding: 20px 0 !important;
  }
  .intro-text {
    text-align: center;
  }
  .intro-img {
    
  }
}
@media screen and (max-width: 800px) {
  .intro-img {
    border-radius: 20px !important;
    width: 100% !important;
  }
  .form-containear {
    padding: 40px 0 !important;
    .font32 {
      font-size: 24px;
    }
    .form-item {
      max-width: 300px !important;
    }
  }
 
}
@media screen and (max-width: 500px) {
  .channel-form {
    padding: 20px !important;
  }
  .radio-item {
    margin-right: 40px !important;
  }
  .button-send {
    margin: 10px auto !important;
  }
}
.form-wrapper {
  width: 100%;
  justify-content: space-between;
}
.form-box {
  margin-bottom: 20px;
}
.intro-text {
  margin-bottom: 20px;
  margin-right: 20px;
  min-width: 350px;
}
.channel-form {
    min-width: 220px;
    padding: 40px 60px;
    border-radius: 60px 60px 60px 60px;
    border: 2px solid #767E98;
}
.radio-item {
    margin-right: 92px;
    cursor: pointer;
    .radio-box {
        width: 20px;
        height: 20px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        border: 2px solid #CED2DF;
        border-radius: 50%;
    }
    &.active {
        .radio-box {
            border-color: #353961;
        }
        .radio-box::after {
            content: '';
            width: 10px;
            height: 10px;
            background: #353961;
            border-radius: 50%;
            opacity: 1;
        }
    }
}

input::input-placeholder{
    color: #CED2DF;
    font-size: 20px;
}
.form-containear {
    padding: 80px 0px;

    .form-item {
        max-width: 448px;
        border-bottom: 1px solid #323232;
        input,textarea {
            height: 45px;
            border: none;
            font-size: 20px;
            margin-top: 10px;
            background-color: transparent;
        }
        textarea {
            width: 300px;
            height: 73px;
            margin-top: 20px;
        }
        input:focus,textarea:focus{
            outline: none;
        }
    }
}
.button-send {
    width: 160px;
    height: 60px;
    background: #353961;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-right: 30px;
    cursor: pointer;
}
.job-item {
    padding: 80px 160px;
}
.intro-img {
    border-radius: 80px;
}
.padding-80-0 {
  padding: 80px 0px;
}
.margin-80-160 {
    margin: 80px 160px;
}
.swiper-container {
  width: 100%;
  height: 50vw;
  font-size: 0;
  overflow: hidden;
  .swiper-wrapper {
    width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    background: url('../assets/images/contact_banner.png') center center no-repeat;
    width: 100% !important;
    background-size: 100% 100%!important;
    height: 100%!important;
    .title {
      font-size: 68px;
    }
    .sub-title {
      font-size: 30px;
      opacity: 0.8;
    }
    .text-box {
      top: 0;
      left: 0;
      z-index: 2;
      font-size: 68px;
      position: absolute;
      padding: 10vw 0 0 12vw;
    }
  }
}
</style>