<template>
    <div>
        <div class="flex flex-center f-500 font68 title">
            <p>隐私声明</p>
        </div>
        <div class="padding-40-80" v-html="content.content">
            
        </div>
    </div>
</template>
<script>
import http from '@/request';
import Swiper from 'swiper'
export default {
  name: 'App',
  data () {
    return {
        navList: [
            { name: '服务政策', type: 'SERVER_POLICY' }, { name: '常见问题', type: 'SERVER_QUESTION' }
        ],
        bannerList: [],
        productList: [],
        newsList: [],
        menuIndex: 0,
        content: {}
    }
  },
  mounted () {
    this.getArticleDetail();
  },
  methods: {
    handlerMenu (index) {
        this.menuIndex = index;
        this.getArticleDetail();
    },
    getArticleDetail () {
        http.get('/contentInfo', {
            type: 'POLICY'
        }).then((res) => {
            this.content = res;
        })
    },
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 800px) {
  .padding-40-80 {
    padding: 20px 40px !important;
}
}
    .title {
        padding: 0 80px;
        height: 190px;
        background: #F2F3F5;
        p {
            margin-top: 40px;
        }
    }
    .padding-40-80 {
        padding: 40px 80px;
    }
</style>