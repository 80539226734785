<template>
    <div>
      <div class="swiper-container pos-rel">
        <div class="swiper-wrapper">
          <div class="swiper-slide pos-rel">
            <!-- <img src="../assets/images/policy_banner.png" alt=""> -->
            <div class="text-box">
              <p class="title f-600">小可智能火热招商中</p>
              <p class="sub-title color-434A63">欢迎您的加入</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-containear">
        <div class="pos-fill mask">
            <div class="w90vw pos-rel">
              <div class="text-box color-w">
                <p class="font68 f-600">销售伙伴</p>
                <p class="font24 margin-t-16">非常感谢您选择小可智能守护精灵，根据国家相关部门规定，我司全面实行三包服务。公司所有产品售出前均检验合格，如遇到问题请联系点可服务热线：400-965-6822。</p>
              </div>
              
            </div>
            <div class="button-box flex font28 flex-wrap">
              <a href="/contactUs"><div class="btn-contact flex flex-center color-w">联系我们</div></a>
              <div @click="goPage" class="btn-policy  flex flex-center pointer">招商政策</div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import http from '@/request';
export default {
  name: 'App',
  data () {
    return {
        navList: [
            { name: '首页' }, { name: '品牌' }, { name: '产品' }, { name: '商城' },{ name: '联系我们' }
        ],

    }
  },
  mounted () {

  },
  methods: {
    goPage () {
      window.open('https://dk-official.oss-cn-hangzhou.aliyuncs.com/official_pro/other/%E6%B7%B1%E5%85%A5%E7%90%86%E8%A7%A3Java%E8%99%9A%E6%8B%9F%E6%9C%BA%EF%BC%9AJVM%E9%AB%98%E7%BA%A7%E7%89%B9%E6%80%A7%E4%B8%8E%E6%9C%80%E4%BD%B3%E5%AE%9E%E8%B7%B5%EF%BC%88%E7%AC%AC3%E7%89%88%EF%BC%89%20%E5%91%A8%E5%BF%97%E6%98%8E.pdf', '_target')
    }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 980px) {
  .swiper-container {
    .title {
      font-size: 3vw;
    }
    .sub-title {
      font-size: 2vw !important;
    }
  }
  .bottom-containear {
    .text-box {
      padding: 10px 0 !important;
    }
    .font68 {
      font-size: 3vw;
    }
    .font24 {
      font-size: 2vw !important;
    }
    .button-box {
      div {
        width: 100px !important;
        height: 36px !important;
        font-size: 12px !important;
      }
    }
  }
}
.bottom-containear {
    height: 50vw;
    background: url('../assets/images/policy_bottom_banner.png') no-repeat center;
    background-size: 100% 100%;
    position: relative;
    .button-box {
        position: absolute;
        left: 80px;
        bottom: 4vw;
        div {
            width: 200px;
            height: 72px;
            background: #FF6442;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            margin-right: 30px;
        }
        .btn-policy {
            color: #FF6442;
            background: transparent;
            border: 1px solid #FF6442;
        }
    }
    .text-box {
        padding: 80px 0;
    }
    .mask {
        
    }
}
.swiper-container {
  width: 100%;
  height: 50vw;
  font-size: 0;
  overflow: hidden;
  .swiper-wrapper {
    width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .switch-box {
    position: absolute;
    right: 90px;
    bottom: 20px;
    z-index: 2;
    font-size: 14px;
    div {
      width: 40px;
      height: 40px;
      border-radius: 11px 11px 11px 11px;
      opacity: 1;
      border: 1px solid #434A63;
      margin-right: 12px;
      color: #434A63;
      cursor: pointer;
    }
  }
  .swiper-slide {
    background: url('../assets/images/policy_banner.png') center center no-repeat;
    width: 100% !important;
    background-size: 100% 100%!important;
    .text-box {
      top: 0;
      left: 0;
      z-index: 2;
      font-size: 68px;
      position: absolute;
      padding: 10vw 0 0 12vw;
    }
    .sub-title {
      font-size: 40px;
      opacity: 0.8;
    }
    .btn {
      width: 160px;
      height: 42px;
      font-size: 14px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      margin-top: 30px;
      border: 1px solid #FFFFFF;
    }
  }
}
.product-containear {
  background-color: #F2F3F5;
  .title {
    font-size: 50px;
    padding-top: 80px;
    color: #434A63;
  }
  .product-box {
    margin: 0 auto;
  }
  .product-item {
    margin-right: 60px;
    margin-bottom: 40px;
    img {
      width: 180px;
      height: 180px;
      border-radius: 30px;
    }
    .product-price {
      margin-top: 4px;
    }
    .button-pay {
      width: 40px;
      height: 20px;
      background: #353961;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      margin: 10px 0;
    }
    .btn-enter {
      margin-top: 8px;
      color: #353961;
    }
  }
}
.news-box {
  margin: 80px;
  margin-top: 80px;
  .btn {
    width: 128px;
    height: 40px;
    margin-top: 36px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid rgba(0,0,0,0.8);
  }
  .news-list {
    
    margin-left: 146px;
    border-top: 1px solid #E8E9EB;
    .news-item {
      padding: 40px 0;
      padding-left: 70px;
      border-bottom: 1px solid #E8E9EB;
      .title {
        margin-left: 36px;
      }
    }
  }
}
</style>