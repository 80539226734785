<template>
    <div>
        <div 
        :class="scrollTop >= 60 ? 'bg-w':''"
        class="nav-wrapper flex flex-align-center flex-space-between">
            <img
            @click="goPage('/')"
             class="logo" src="../assets/images/nav_logo.png" alt="" />
            <div class="pc-nav">
                <div class="flex flex-align-center">
                    <!-- <el-menu :default-active="activeIndex" class="flex" :router="true">
                        <el-menu-item 
                        :route="item.path"
                        v-for="(item, index) in navList" :key="index" :index="index">
                            {{ item.name }}
                        </el-menu-item>
                    </el-menu> -->
                    <ul class="flex font16 nav-ul">
                        <li 
                        :class="currentPath == item.path ? 'is-active':''"
                        @click="goPage(item)"
                        v-for="(item, index) in navList" :key="index">
                            {{  item.name }}
                        </li>
                    </ul>
                    <!-- <img src="../assets/images/icon_search.png" alt=""> -->
                </div>
            </div>
            <div class="mobile-nav" @click="handlerMenu">
               <img 
               style="width:30px;height:30px"
               src="../assets/images/icon_menu.png" alt="">

            </div>         
        </div>
        <el-drawer
        size="50"
            :show-close="false"
            :visible="drawer"
            :modal-append-to-body="false"
            direction="rtl"
            :withHeader="false"
            :before-close="handleClose">
            <ul class="flex font16 nav-ul flex-column">
                <li 
                :class="currentPath == item.path ? 'bg-active':''"
                @click.stop="goPage(item)"
                v-for="(item, index) in navList" :key="index">
                    {{  item.name }}
                </li>
            </ul>
        </el-drawer>
        <!-- <div class="size-box"></div> -->
    </div>
</template>
<script>
import router from '@/router'
export default {
  name: 'App',
  data () {
    return {
        drawer: false,
        activeIndex: 0,
        navList: [
            { name: '首页', path: '/' }, { name: '品牌', path: '/brand' }, { name: '产品', path: '/productList' }, { name: '商城', path: '/shoppingMall' }, { name: '服务', path: '/service/0' },{ name: '联系我们',path: '/contactUs' }
        ],
        currentPath: '/',
        scrollTop: 0
    }
  },
  mounted () {
    let routeInfo = {}
    this.currentPath = this.$router.currentRoute.path;
    routeInfo = this.navList.find((item) => item.path == this.$router.currentRoute.path);
   
    
    
    router.beforeEach((to, from, next) => {
        routeInfo = this.navList.find((item) => item.path == to.fullPath);
        this.currentPath = to.path
        next()
    })
    // if (routeInfo.name) document.title = routeInfo.name;
    window.addEventListener('scroll', this.scrollToTop)
  },
  methods: {
    handleClose () {
        this.drawer = false;
    },
    goPage (item) {
    console.log('item******', item)
        if (item == '/') item = {
            path: '/',
            name: '首页'
        }
        this.drawer = false;
        document.title = item.name == '首页' ? '小可智能':item.name;
        this.$router.push({ path: item.path })
    },
    handlerMenu () {
        this.drawer = true;
    },
    scrollToTop() {
        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    },

  }
}
</script>
<style lang="scss" scoped>
/deep/.el-menu {
    border: none;
}
/deep/.is-active {
    position: relative;
    color: #0063F5 !important;
}
/deep/.is-active::after {
    content: '';
    left: -2px;
    right: -2px;
    height: 2px;
    position: absolute;
    bottom: -10px;
    background-color: #0063F5;
}
/deep/.el-menu-item {
    color: #000;
    font-size: 16px;
}
/deep/.el-menu-item:hover{
    background: #fff;
}
/deep/.el-menu-item:active{
    background: #fff;
}
.bg-active {
    color: white;
    background-color: #0063F5;
}
@media screen and (max-width: 630px) {
    .logo {
        width: 100px !important;
        height: 19px !important;
        position: relative;
    }
    .size-box {
        height: 60px !important;
    }
    .pc-nav {
        display: none !important;
    }
    .mobile-nav {
        display: block !important;
    }
    .nav-ul {
        background: #fff;
        width: 200px;
        position: relative;
        z-index: 99999999999;
        li {
            width: 200px;
            height: 60px;
            line-height: 60px;
            text-align: center;
        }
    }
    .nav-wrapper {
        height: 50px !important;
        padding: 0 20px !important;
    }
}
.mobile-nav{
    display: none;
}
.size-box {
    height: 100px;
}
    .nav-wrapper {
        height: 60px;
        padding: 0 50px;
        padding-right: 18px;
        cursor: pointer;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 22;
        background: hsla(0,0%,100%,0.4);
        .logo {
            width: 144px;
            height: 28px;
            position: relative;
            z-index: 2222;
            pointer-events: all !important;
        }
    }
    .nav-ul {
        li {
            cursor: pointer;
            font-weight: 600;
            margin-right: 22px;
        }
    }
    .nav-wrapper:hover {
        background-color: #fff;
    }
</style>