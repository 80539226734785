import Vue from 'vue'
import App from './App.vue'
import '@/assets/style/base.scss';
import "../node_modules/swiper/swiper.css";
import ElementUI from 'element-ui';
import Router from 'vue-router'
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false

import router from '@/router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
 return originalPush.call(this, location).catch(err => err)
}
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  next()
})
Vue.use(ElementUI);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
