<template>
  <div id="app">
    <NavBar></NavBar>
    <router-view></router-view>
    <Footer></Footer>
    <div class="float-button">
      <div 
      @click="contactUs"
      class="bg-item flex flex-center">
        <img src="./assets/images/icon_service.png" alt="">
      </div>
      <div 
      @click="goTop"
      class="margin-t-8  flex flex-center">
        <img 
        style="width: 20px;height: 20px"
        src="./assets/images/arrow_u.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from './components/navBar.vue'
import Footer from './components/fotter.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Footer
  },
  mounted () {

  },
  methods: {
    contactUs () {
        window.open('https://work.weixin.qq.com/kfid/kfcf0be33cc4d920fe2', '_target')
    },
    goTop () {
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style lang="scss">
body {
  overflow-x: hidden;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -webkit-text-size-adjust: 100%;-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
img {
  pointer-events:none;
}
.float-button {
  position: fixed;
  right: 12px;
  z-index: 2222222;
  top: 60%;
  div {
    width: 48px;
    height: 48px;
    cursor: pointer;
    background: #FCFDFF;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05), 0px 0px 6px 0px rgba(0,0,0,0.05);
    border-radius: 16px 16px 16px 16px;
    opacity: 1;
    border: 1px solid rgba(53,57,97,0.8);
  }
}
.margin-t-8 {
  margin-top: 8px;
}
</style>
