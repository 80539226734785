import axios from "axios";
import { Message, Loading } from "element-ui";
import   {config}  from "@/config/index.js";

axios.defaults.baseURL = config.BASE_URL;
axios.defaults.timeout = 20000;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
const http = axios.create();

const post = http.post;
const get = http.get;
http.post = (url, params, options = {}, config) => {
  return post(
    url, 
    params, 
    config
  ).then((data = {}) => {
    return Promise.resolve(data.data ? data.data.data : false);
  });
};

http.get = (url, params, options = {}, config) => {
  return get(
    url, 
    {params}, 
    config
  ).then((data = {}) => {
    return Promise.resolve(data.data ? data.data.data : false);
  });
};

// 请求拦截器
http.interceptors.request.use(
  config => {
    let info = window.localStorage.getItem("info");
    if (info) {
      config.headers.token = `${JSON.parse(info).token}`;
    } 
    // 加载提示
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
http.interceptors.response.use(
  response => {
    if (response.data.code == 0) {
      // 成功

      return response;
    } else if (response.data.code == 1004) {
      // token

    //   window.localStorage.clear();
    //   Message.error("登录过期,请重新登录");
      location.href = "/";
    } else {
      if (response.config.responseType == "blob") {
        // loading.close();
        return response;
      } else {

        Message.error(
          response.data.msg ||
            response.data.message ||
            "数据加载失败，请刷新页面重试！"
        );
      }
    }
  },
  error => {
    // loading.close();
    // Message.error("网络异常，请刷新页面重试！");
    return Promise.reject(error);
  }
);

export default http;

