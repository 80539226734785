import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Home from '@/views/home.vue'
import ProductList from '@/views/productList.vue'
import Provincy from '@/views/provincy.vue'
import Brand from '@/views/brand.vue'
import NewsList from '@/views/newsList.vue'
import ProductDetail from '@/views/productDetail.vue'
import ShoppingMall from '@/views/shoppingMall.vue'
import NewsDetail from '@/views/newsDetail.vue'
import Service from '@/views/service.vue'
import Policy from '@/views/policy.vue'
import Job from '@/views/job.vue'
import ContactUs from '@/views/contactUs.vue'
const routes = [
    {
        path: '/',
        component: Home
    },
    {
        path: '/productList',
        component: ProductList
    },
    {
        path: '/provincy',
        component: Provincy
    },
    {
        path: '/brand',
        component: Brand
    },
    {
        path: '/newsList',
        component: NewsList
    },
    {
        path: '/productDetail',
        name: 'productDetail',
        component: ProductDetail
    },
    {
        path: '/shoppingMall',
        component: ShoppingMall
    },
    {
        path: '/newsDetail',
        name: 'newsDetail',
        component: NewsDetail
    },
    {
        path: '/service/:index',
        component: Service
    },
    {
        path: '/policy',
        component: Policy
    },
    {
        path: '/job',
        component: Job
    },
    {
        path: '/contactUs',
        component: ContactUs
    }
]

const router = new VueRouter({
    routes,
    mode: 'history',
    ignoreDuplicates: true
})

export default router