<template>
    <div>
      <div class="swiper-container pos-rel">
        <div class="swiper-wrapper">
          <div class="swiper-slide pos-rel">
          </div>
          <div class="bottom-info flex flex-align-center flex-wrap">
            <div class="flex bottom-item">
              <img style="width: 18px; height: 18px;margin-top:4px;margin-right: 8px" src="../assets/images/icon_point.png" alt="">
              <div class="color-w">
                <p class="font24 f-600">品牌愿景</p>
                <!-- <p class="font16">成为健康生活的领导品牌</p> -->
              </div>
            </div>
            <div class="flex bottom-item">
              <img style="width: 18px; height: 18px;margin-top:4px;margin-right: 8px" src="../assets/images/icon_point.png" alt="">
              <div class="color-w">
                <p class="font24 f-600">企业愿景</p>
                <!-- <p class="font16">成为健康的长久的企业</p> -->
              </div>
            </div>
            <div class="flex bottom-item">
              <img style="width: 18px; height: 18px;margin-top:4px;margin-right: 8px" src="../assets/images/icon_point.png" alt="">
              <div class="color-w">
                <p class="font24 f-600">企业核心价值观</p>
                <!-- <p class="font16">本分、诚信、团队、品质、消费者导向</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="company-containear">
        <div v-html="content"></div>
      </div>
    </div>
</template>
<script>
import http from '@/request';
export default {
  name: 'App',
  data () {
    return {
      content: ''
    }
  },
  mounted () {
    this.getBrand();
  },
  methods: {
    getBrand () {
      http.get('/contentInfo', {
            type: 'BRAND'
        }).then((res) => {
            this.content = res.content;
        })
    }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 905px) {
  .bottom-info {
    height: 70px !important;
    padding: 0 10px !important;
    justify-content: center;
  }
  .bottom-item {
    width: auto !important;
    height: auto !important;
    margin-right: 20px !important;
    .font24 {
      font-size: 10px !important;
    }
    .font16 {
      font-size: 10px !important;
    }
  }
}
@media screen and (max-width: 460px) {
  .bottom-item {
    .font24 {
      font-size: 12px !important;
    }
    .font16 {
      font-size: 10px !important;
    }
  }
}
.company-containear {
  margin: 40px 80px;
}
.bottom-info {
  background-color: rgba(0,0,0,0.7);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 140px;
  padding: 0 80px;
  .bottom-item {
    align-items: center;
    width: 306px;
    margin-right: 40px;
  }
}
.swiper-container {
  width: 100%;
  height: 50vw;
  font-size: 0;
  overflow: hidden;
  .swiper-wrapper {
    width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .switch-box {
    position: absolute;
    right: 90px;
    bottom: 20px;
    z-index: 2;
    font-size: 14px;
    div {
      width: 40px;
      height: 40px;
      border-radius: 11px 11px 11px 11px;
      opacity: 1;
      border: 1px solid #434A63;
      margin-right: 12px;
      color: #434A63;
      cursor: pointer;
    }
  }
  .swiper-slide {
    background: url('../assets/images/brand_banner.png') center center no-repeat;
    width: 100% !important;
    background-size: 100% 100%!important;
    height: 100%!important;
    .title {
        margin: 30px 0;
        font-size: 60px;
    }
    .text-box {
      top: 0;
      left: 0;
      z-index: 2;
      font-size: 68px;
      position: absolute;
      padding: 5vw 0 0 6vw;
    }
    .sub-title {
      opacity: 0.8;
    }
    .btn {
      width: 160px;
      height: 42px;
      font-size: 14px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      margin-top: 30px;
      border: 1px solid #FFFFFF;
    }
  }
}
.product-containear {
  background-color: #F2F3F5;
  .title {
    font-size: 50px;
    padding-top: 80px;
    color: #434A63;
  }
  .product-box {
    margin: 0 auto;
  }
  .product-item {
    margin-right: 60px;
    margin-bottom: 40px;
    img {
      width: 180px;
      height: 180px;
      border-radius: 30px;
    }
    .product-price {
      margin-top: 4px;
    }
    .button-pay {
      width: 40px;
      height: 20px;
      background: #353961;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      margin: 10px 0;
    }
    .btn-enter {
      margin-top: 8px;
      color: #353961;
    }
  }
}
.news-box {
  margin: 80px;
  margin-top: 80px;
  .btn {
    width: 128px;
    height: 40px;
    margin-top: 36px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid rgba(0,0,0,0.8);
  }
  .news-list {
    
    margin-left: 146px;
    border-top: 1px solid #E8E9EB;
    .news-item {
      padding: 40px 0;
      padding-left: 70px;
      border-bottom: 1px solid #E8E9EB;
      .title {
        margin-left: 36px;
      }
    }
  }
}
</style>